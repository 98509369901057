import React, { ElementType } from 'react'
import ReactDOM from 'react-dom/client'

import { isBot } from '@/utils/isBot'
import { lazyLoad } from '@/utils/lazyLoad'
import { toBoolean } from '@/utils/string'

// Include all components here
// Note: to support chunking, this must match the app component folder name i.e. all subfolders,
// and each must have its entrypoint component exported as a default export from a `.tsx` file with
// the same name e.g. `./DiscoveryMap/DiscoveryMap.tsx`. If not, the app component will not work.
const appComponents = [
  'DiscoveryMap',
  'FavouriteLocation',
  'RoadTripMap',
  'ItineraryInfo',
  'FlipCardModule',
  'ProductMapModule',
] as const

/** A union representing all available app components. */
export type AppComponent = (typeof appComponents)[number]

const appComponentPromises = new Map<AppComponent, Promise<ElementType>>()

function loadAppComponent(appComponent: AppComponent) {
  let componentPromise = appComponentPromises.get(appComponent)
  if (!componentPromise) {
    componentPromise = import(`@/apps/${appComponent}/${appComponent}.tsx`).then(
      (module) => module.default
    )
    appComponentPromises.set(appComponent, componentPromise)
  }
  return componentPromise
}

async function initializeApp(appComponent: AppComponent) {
  const counts: Record<string, number> = {}
  try {
    const selector = `[data-react-app="${appComponent}"]`
    const containers = document.querySelectorAll<HTMLElement>(selector)
    if (!containers.length) {
      return { appComponent, counts } as const
    }

    for (const container of containers) {
      const { props, lazy, blockBots } = extractConfig(container)

      if (blockBots && isBot()) {
        counts.blockedBot = (counts.blockedBot ?? 0) + 1
        continue
      }

      if (lazy) {
        lazyLoad(container, () => renderComponent(appComponent, container, props))
        counts.lazy = (counts.lazy ?? 0) + 1
      } else {
        await renderComponent(appComponent, container, props)
        counts.rendered = (counts.rendered ?? 0) + 1
      }
    }
    return { appComponent, counts } as const
  } catch (error) {
    return { appComponent, counts, error } as const
  }
}

interface AppComponentConfig {
  props: Record<string, unknown>
  lazy: boolean
  blockBots: boolean
}

async function renderComponent(
  appComponent: AppComponent,
  container: HTMLElement,
  props: Record<string, unknown>
) {
  const Component = await loadAppComponent(appComponent)
  container.classList.add('vvtw')
  ReactDOM.createRoot(container).render(
    <React.StrictMode>
      <Component {...props} />
    </React.StrictMode>
  )
}

function extractConfig(element: HTMLElement): AppComponentConfig {
  const result = {
    props: {},
    lazy: toBoolean(element.dataset.lazy),
    blockBots: toBoolean(element.dataset.blockBots),
  }

  const propsString = element.dataset.props?.trim()
  if (!propsString) {
    return result
  }
  try {
    if (propsString.startsWith('{') && propsString.endsWith('}')) {
      result.props = JSON.parse(propsString) as Record<string, unknown>
      return result
    }
  } catch {
    // Nothing to do here
  }

  console.warn('Invalid data-props JSON for app. (Will be rendered without props.)', element)
  return result
}

/** Loads all registered apps (members of `appComponents`), or the provided subset, if they are present within the DOM.
 * @param apps {AppComponent[]} An optional subset of apps to load.
 */
export function initializeApps(apps = appComponents): void {
  void Promise.all(apps.map(initializeApp)).then((results) => {
    if (!import.meta.env.PROD) {
      console.debug('apps:init', results)
    }
  })
}
